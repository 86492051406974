const leTaller = [
    {
        "name": "Fractal Circular",
        "view": "/static/le_taller/Fractal_Circular",
        "video": "https://www.youtube.com/watch?v=moOHBQwiUlg"
    },
    {
        "name": "Fractal Árbol",
        "view": "/static/le_taller/Fractal_Arbol",
        "video": "https://www.youtube.com/watch?v=xL9RU7SIjIA"
    },
    {
        "name": "Cellular Automata 1D",
        "view": "/static/le_taller/CellularAutomata_1D",
        "video": "https://www.youtube.com/watch?v=ZiOa-inz9Vo"
    },
    {
        "name": "Cellular Automata 2D",
        "view": "/static/le_taller/CellularAutomata_2D",
        "video": "https://www.youtube.com/watch?v=cAzcHggQ2a0"
    },
    {
        "name": "GRIS",
        "view": "/static/le_taller/GRIS",
        "video": "https://www.youtube.com/watch?v=WwscUolqltg"
    },
    {
        "name": "Rosa de Maurer",
        "view": "/static/le_taller/MaurerRose",
        "video": "https://www.youtube.com/watch?v=nPoPQPRDGWo"
    },
    {
        "name": "Laberintos",
        "view": "/static/le_taller/Maze",
        "video": "https://www.youtube.com/watch?v=3koPbdj4pHY"
    },
    {
        "name": "Reacción difusión",
        "view": "/static/le_taller/ReactionDiffusion",
        "video": "https://www.youtube.com/watch?v=hP3KAu4wnZo"
    },
    {
        "name": "Algoritmos genéticos",
        "view": "/static/le_taller/GeneticAlgorithms",
        "video": "https://www.youtube.com/watch?v=7wOI12ZfTU0"
    },
    {
        "name": "Simulador de partículas",
        "view": "/static/le_taller/PixelSimulator",
        "video": "https://www.youtube.com/watch?v=IlCxv8ScuDY"
    }
]

export default leTaller