<template>
  <div>
    <b-card
      body-bg-variant="light"
      border-variant="light"
      >
      <template slot="header">
        <h2>Le taller</h2>
      </template>


      <h4 class="mb-5">
        Repositorio de los mini proyectos:
        <a href="https://github.com/danielwayota/le_taller">
          <sl-icon icon="social-github" no-svg />
        </a>
      </h4>

      <div>
        <div v-for="(item, index) in items" v-bind:key="index" class="my-2 py-2 row border-bottom">
          <div class="col-10">
            {{item.name}}
          </div>

          <div class="col-1">
            <a :href="item.view" target="_blank">
              <sl-icon icon="eye" no-svg />
            </a>
          </div>

          <div class="col-1">
            <a target="_blank" v-if="item.video" :href="item.video">
              <sl-icon icon="social-youtube" no-svg />
            </a>
          </div>
        </div>
      </div>

    </b-card>
  </div>
</template>

<script>

import leTaller from '@/constants/leTaller'

export default {
  name: 'LeTaller',
  mounted() {
    this.items = leTaller
  },
  data() {
    return {
      items: []
    }
  }
}
</script>