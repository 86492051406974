const projects = [
    {
        "name": "Combate táctico",
        "download": "https://github.com/danielwayota/TacticalCombat/archive/master.zip",
        "repository": "https://github.com/danielwayota/TacticalCombat"
    },
    {
        "name": "Experiments",
        "download": "https://github.com/danielwayota/Experiments/archive/master.zip",
        "repository": "https://github.com/danielwayota/Experiments"
    },
    {
        "name": "Unos Remakes",
        "download": "https://github.com/danielwayota/Unos_Remakes/archive/master.zip",
        "repository": "https://github.com/danielwayota/Unos_Remakes"
    },
    {
        "name": "Turn based Combat",
        "download": "https://github.com/danielwayota/TurnBasedCombat/archive/refs/heads/master.zip",
        "repository": "https://github.com/danielwayota/TurnBasedCombat"
    },
    {
        "name": "EPIC! 3 en raya",
        "download": "https://github.com/danielwayota/EPIC_TicTacToe/archive/master.zip",
        "repository": "https://github.com/danielwayota/EPIC_TicTacToe"
    },
    {
        "name": "CHIP-8 Emulator",
        "download": "https://github.com/danielwayota/chip-8_emulator/archive/master.zip",
        "repository": "https://github.com/danielwayota/chip-8_emulator"
    },
    {
        "name": "WillVsZombies",
        "download": "/static/proyects/WillvsZombies.zip",
        "repository": ""
    },
    {
        "name": "TopDownShooter",
        "download": "",
        "repository": ""
    },
    {
        "name": "Minecraft",
        "download": "/static/proyects/UnityMinecraft.zip",
        "repository": ""
    },
    {
        "name": "Moba",
        "download": "",
        "repository": ""
    },
    {
        "name": "RTS",
        "download": "/static/proyects/RTS.zip",
        "repository": ""
    },
    {
        "name": "RTS 2",
        "download": "https://github.com/danielwayota/RTS_2/archive/master.zip",
        "repository": "https://github.com/danielwayota/RTS_2"
    }
]

export default projects
