<template>
  <header>
    <b-navbar variant="dark" type="dark">
      <b-navbar-brand>
        <img width="64px" src="./assets/logo.svg" />
        <span class="d-none d-sm-inline">Daniel Wayota</span>
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto">
        <b-nav-item href="https://www.youtube.com/channel/UCZYA2D3kMseACGJMItZ3NTg">
          <sl-icon icon="social-youtube" no-svg />
        </b-nav-item>
        <b-nav-item href="https://twitter.com/DanielWayota">
          <sl-icon icon="social-twitter" no-svg />
        </b-nav-item>

        <div class="nav-item">
          <form class="pt-2" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="3G8E8JPQCCWPL" />
            <input
              type="image"
              src="https://www.paypalobjects.com/es_ES/ES/i/btn/btn_donate_SM.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Botón Donar con PayPal"
            />
            <img
              alt
              border="0"
              src="https://www.paypal.com/es_ES/i/scr/pixel.gif"
              width="1"
              height="1"
            />
          </form>
        </div>
      </b-navbar-nav>
    </b-navbar>

    <b-nav class="bg-nav text-light" align="center">
      <b-nav-text
        v-for="(link, index) in links"
        v-bind:key="index"
        :class="{'bg-nav-item-selected': isRouteActive(link.to), 'px-4': true}"
      >
        <router-link class="text-waito" v-if="link.to" :to="link.to">{{link.text}}</router-link>
        <!-- External -->
        <a v-else class="text-waito" :href="link.external" :target="link.external ? '_blank': ''" >
          {{link.text}}
          <sl-icon class="ml-1" icon="action-redo" no-svg />
        </a>
      </b-nav-text>
    </b-nav>

    <b-container class="mt-2">
      <router-view />
    </b-container>
  </header>
</template>

<script>
export default {
  name: "App",
  mounted() {},
  data() {
    return {
      links: [
        {
          text: "Inicio",
          to: { name: "home" }
        },
        {
          text: "Proyectos",
          to: { name: "projects" }
        },
        {
          text: "Le taller",
          to: { name: "Le Taller" }
        },
        {
          text: "A.I.",
          to: { name: "AI" }
        },
        {
          text: "Juegos",
          external: "https://danielwayota.itch.io/"
        },
      ]
    };
  },
  methods: {
    isRouteActive(route) {
      if (!route) {
        return false;
      }

      const current = this.$router.currentRoute;

      return route.name === current.name;
    }
  }
};
</script>

<style lang="scss">
@import "./scss/theme.scss";
</style>
