const ai = [
    {
        "name": "Algoritmos genéticos",
        "download": "https://github.com/danielwayota/GeneticAlgorithmsChamber/archive/master.zip",
        "repository": "https://github.com/danielwayota/GeneticAlgorithmsChamber"
    },
    {
        "name": "Neuro Evo Runner",
        "download": "https://github.com/danielwayota/NeuroevoRunner/archive/master.zip",
        "repository": "https://github.com/danielwayota/NeuroevoRunner"
    },
    {
        "name": "Neuro Evo Shooter",
        "download": "https://github.com/danielwayota/NeuroevoShooter/archive/master.zip",
        "repository": "https://github.com/danielwayota/NeuroevoShooter"
    },
    {
        "name": "Árboles de decisión",
        "download": "https://github.com/danielwayota/DecisionTrees/archive/master.zip",
        "repository": "https://github.com/danielwayota/DecisionTrees"
    }
]

export default ai
