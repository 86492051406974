import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'

import SimpleLineIcons from 'vue-simple-line'

Vue.use(BootstrapVue)

Vue.component('sl-icon', SimpleLineIcons)

Vue.config.productionTip = false

setTimeout(() => {
  const element = document.querySelector('.disclaimer');
  element.innerHTML = '';
}, 1000);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
