import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Projects from '../views/Projects.vue'
import LeTaller from '../views/LeTaller.vue'
import AI from '../views/AI.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects
  },
  {
    path: '/le-taller',
    name: 'Le Taller',
    component: LeTaller
  },
  {
    path: '/ai',
    name: 'AI',
    component: AI
  }
]

const router = new VueRouter({
  routes
})

export default router
