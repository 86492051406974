<template>
  <div>
    <b-card
      body-bg-variant="dark"
      body-text-variant="light"
      border-variant="light"
      class="mb-2">
      <!-- En línea -->
      <h2 class="my-0 d-none d-sm-block">
        <img
          alt="NoYo"
          class="mr-2 rounded-circle"
          src="../assets/yo.jpg" width="100px" />
          <span>Hola a todos</span>
      </h2>
      <!-- En vetical -->
      <div class="d-sm-none">
        <div class="text-center">
          <img
            alt="NoYo"
            class="mr-2 rounded-circle"
            src="../assets/yo.jpg" width="100px" />
        </div>
        <div class="text-center">
          <h2>Hola a todos</h2>
        </div>
      </div>
    </b-card>

    <b-card
      body-bg-variant="light"
      border-variant="light-darker"
      class="mb-2"
    >
      <div>Mi nombre es <b>Daniel González</b>, pero por ahí me conocen como <strong>Daniel Wayota</strong>.</div>
      LLevo casi una décadada programando y aún no sé si es algo bueno o malo...
    </b-card>

    <div class="bg-secondary text-light mb-2">
      <div class="d-flex justify-content-around">

        <div class="text-center p-1">
          <h5>Proyectos</h5>
          <h3>{{projectCount}}</h3>
        </div>

        <div class="text-center p-1">
          <h5>Experimentos</h5>
          <h3>{{experimentsCount}}</h3>
        </div>
      </div>
    </div>

    <b-card
      body-bg-variant="light"
      border-variant="light-darker"
      >
      <div>
        <p>Soy nativo de Tenerife, España, y desde que llegó a mis manos mi primera GameBoy, he sido un gran fan de los videojuegos. Siempre he querido saber cómo se hicieron las grandes obras que disfruté cuando era niño y sigo disfrutando. Así que más pronto que tarde, aprendí a programar y comencé a hacer pequeñas cosas.</p>

        <p>Actualmente trabajo como desarrollador de servicios web, pero desde que consigo tiempo libre, lo dedico a hacer proyectos (que no suelo terminar XD) y a aprender cosas nuevas.</p>

        <p>En esta web he recopilado los proyectos que he ido realizando para mi canal y con el tiempo iré añadiendo más.</p>

        <p>Bienvenido!</p>
      </div>
    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src

import projects from '@/constants/projects'
import leTaller from '@/constants/leTaller'

export default {
  name: 'home',
  data() {
    return {
      projectCount: 0,
      experimentsCount: 0
    }
  },
  mounted() {
    const doTheThing = () => {
      this.projectCount++
      this.experimentsCount++

      let done1 = false
      if (this.projectCount > projects.length) {
        this.projectCount = projects.length
        done1 = true
      }

      let done2 = false
      if (this.experimentsCount > leTaller.length) {
        this.experimentsCount = leTaller.length
        done2 = true
      }

      if (done1 && done2) {
        return
      }

      setTimeout(doTheThing, 500 / projects.length)
    }

    setTimeout(doTheThing, 500)
  },

}
</script>
