<template>
  <div>
    <b-card
      body-bg-variant="light"
      border-variant="light"
      >
      <template slot="header">
        <h2>Proyectos</h2>
      </template>

      <div>
        <div v-for="(item, index) in items" v-bind:key="index" class="my-2 py-2 row border-bottom">
          <div class="col-10">
            {{item.name}}
          </div>

          <div class="col-1">
            <a v-if="item.download" :href="item.download" target="_blank">
              <sl-icon icon="cloud-download" no-svg />
            </a>
            <span v-else>
              <sl-icon icon="cloud-download" class="text-danger" no-svg />
            </span>
          </div>

          <div class="col-1">
            <a target="_blank" v-if="item.repository" :href="item.repository">
              <sl-icon icon="social-github" no-svg />
            </a>
          </div>
        </div>
      </div>

    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src
import projects from '@/constants/projects'

export default {
  name: 'projects',
  components: {
  },
  mounted() {
    this.items = projects
  },
  data() {
    return {
      items: []
    }
  }
}
</script>
